<template>
  <v-app-bar
    id="app-bar"
    absolute
    app
    color="transparent"
    flat
    height="75"
  >
    <v-btn
      class="mr-3"
      elevation="1"
      fab
      small
      @click="setDrawer(!drawer)"
    >
      <v-icon v-if="value">
        mdi-view-quilt
      </v-icon>

      <v-icon v-else>
        mdi-dots-vertical
      </v-icon>
    </v-btn>

    <v-toolbar-title
      class="hidden-sm-and-down font-weight-light"
    />
    <!--
    <v-toolbar-title
      class="hidden-sm-and-down font-weight-light"
      v-text="$route.name"
    />
    -->

    <div class="mx-8" />

    <v-text-field
      label="Rechercher ... (bientôt disponible)"
      hide-details
      disabled
    >
      <template
        v-if="$vuetify.breakpoint.mdAndUp"
        v-slot:append
      >
        <v-icon>mdi-magnify</v-icon>
      </template>
    </v-text-field>

    <div class="mx-6" />

    <v-menu
      bottom
      left
      offset-y
      origin="top right"
      transition="scale-transition"
    >
      <!--
      <template v-slot:activator="{ attrs, on }">
        <v-btn
          class="ml-2"
          min-width="0"
          text
          v-bind="attrs"
          v-on="on"
        >
          <v-badge
            color="red"
            overlap
            bordered
          >
            <template v-slot:badge>
              <span>5</span>
            </template>

            <v-icon>mdi-bell</v-icon>
          </v-badge>
        </v-btn>
      </template>
      -->
      <v-list
        :tile="false"
        nav
      >
        <div>
          <app-bar-item
            v-for="(n, i) in notifications"
            :key="`item-${i}`"
          >
            <v-list-item-title v-text="n" />
          </app-bar-item>
        </div>
      </v-list>
    </v-menu>

    <v-btn
      class="ml-2"
      min-width="0"
      text
      :to="{ name: 'Profil' }"
    >
      <v-icon class="mr-2">mdi-account</v-icon>
      {{ user.firstName }}
    </v-btn>

    <v-btn
      class="ml-2"
      min-width="0"
      text
      :to="{ name: 'Configuration' }"
    >
      <v-icon>mdi-cog</v-icon>
    </v-btn>

    <v-btn
      class="ml-2"
      min-width="0"
      text
      @click="disconnect()"
    >
      <v-icon>mdi-power</v-icon>
    </v-btn>

  </v-app-bar>
</template>

<script>
  // Components
  /* import { VHover, VListItem } from 'vuetify/lib' */

  // Utilities
  import { mapState, mapMutations } from 'vuex'

  export default {
    name: 'DashboardCoreAppBar',

    components: {
      /*
      AppBarItem: {
        render (h) {
          return h(VHover, {
            scopedSlots: {
              default: ({ hover }) => {
                return h(VListItem, {
                  attrs: this.$attrs,
                  class: {
                    'black--text': !hover,
                    'white--text secondary elevation-12': hover,
                  },
                  props: {
                    activeClass: '',
                    dark: hover,
                    link: true,
                    ...this.$attrs,
                  },
                }, this.$slots.default)
              },
            },
          })
        },
      },
      */
    },

    props: {
      value: {
        type: Boolean,
        default: false,
      },
    },

    data: () => ({
      notifications: [],
      user: JSON.parse(localStorage.getItem('mcdm-user')),
    }),

    computed: {
      ...mapState(['drawer']),
    },

    methods: {
      disconnect() {
        localStorage.removeItem('mcdm-user');
        localStorage.removeItem('mcdm-token');
        sessionStorage.removeItem('mcdm-token');
        this.axios.delete('/auth/refresh', { withCredentials: true });
        delete this.axios.defaults.headers.common.Authorization;
        this.$router.push({ name: 'Login' });
      },

      ...mapMutations({
        setDrawer: 'SET_DRAWER',
      }),
    },
  }
</script>
